import React, { useContext, useEffect, useMemo } from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import Sns from '../components/utils/sns';
import BreadCrumb from '../components/utils/bread-crumb';
import PageMover, { PAGE_MOVER_MODE } from '../components/utils/page-mover';
import StickyBottom from '../components/utils/sticky-bottom';
import { OnlyPC, OnlySP } from '../helper/media-helper';

import { PATH } from '../helper/config';
import '../css/dictionary_detail.css';
import LazyImage from '../components/utils/lazy-image';
import { AppContext } from '../context/context';

const DictionaryDetail: React.FunctionComponent<any> = ({ data, pageContext }) => {
  const context = useContext(AppContext);
  const localData = data.main;
  let localePath: string = '';
  if (context.locale === 'en_US') localePath = '/en';

  useEffect(() => {
    if (pageContext.locale === 'ja') {
      if (localData.translated.length) {
        context.setLanguage(
          {
            ja: `${PATH.DICTIONARY_DETAIL}${localData.databaseId}`,
            en: `/en${PATH.DICTIONARY_DETAIL}${localData.translated[0].databaseId}`
          },
          pageContext.locale
        );
      } else {
        context.setLanguage(
          {
            ja: `${PATH.DICTIONARY_DETAIL}${localData.databaseId}`,
            en: `/en${PATH.DICTIONARY}`
          },
          pageContext.locale
        );
      }
    } else {
      if (localData.translated.length) {
        context.setLanguage(
          {
            ja: `${PATH.DICTIONARY_DETAIL}${localData.translated[0].databaseId}`,
            en: `/en${PATH.DICTIONARY_DETAIL}${localData.databaseId}`
          },
          pageContext.locale
        );
      } else {
        context.setLanguage(
          {
            ja: `${PATH.DICTIONARY}`,
            en: `/en${PATH.DICTIONARY_DETAIL}${localData.databaseId}`
          },
          pageContext.locale
        );
      }
    }
  }, []);

  const createNavigation = (): any => {
    let detectID: number = 0;
    for (let i: number = 0; i < data.navigation.edges.length; i++) {
      if (data.navigation.edges[i].node.databaseId === pageContext.databaseId) {
        detectID = i;
      }
    }
    let prevID: number = detectID - 1;
    let nextID: number = detectID + 1;
    if (prevID < 0) prevID = data.navigation.edges.length - 1;
    if (nextID >= data.navigation.edges.length) nextID = 0;

    return {
      prev: { ...data.navigation.edges[prevID].node },
      next: { ...data.navigation.edges[nextID].node }
    };
  };
  const navigation: any = useMemo(() => createNavigation(), []);

  useEffect(() => {
    let metaTag;
    metaTag = document.querySelector(`meta[name="Description"]`);
    if (metaTag) metaTag.remove();

    metaTag = document.querySelector(`meta[name="og:description"]`);
    if (metaTag) metaTag.remove();

    metaTag = document.querySelector(`meta[name="twitter:description"]`);
    if (metaTag) metaTag.remove();
  }, []);

  return (
    <article className="dictionary-detail">
      <Helmet>
        <title>{data.main.gqlDictionariesAcf.title} | POST-FAKE</title>
        <meta name="Description" content="" />
        <meta property="og:description" content="" />
        <meta property="og:title" content={`${data.main.gqlDictionariesAcf.title} | POST-FAKE`} />
        <meta property="og:url" content={`https://postfake.com${context.localePath}${PATH.DICTIONARY_DETAIL}${data.main.databaseId}`} />
        {data.main &&
          data.main.gqlDictionariesAcf &&
          data.main.gqlDictionariesAcf.images &&
          data.main.gqlDictionariesAcf.images[0] &&
          data.main.gqlDictionariesAcf.images[0].image &&
          data.main.gqlDictionariesAcf.images[0].image.mediaItemUrl && (
            <meta property="og:image" content={data.main.gqlDictionariesAcf.images[0].image.mediaItemUrl} />
          )}
        <meta name="twitter:site" content={`${data.main.gqlDictionariesAcf.title} | POST-FAKE`} />
        <meta name="twitter:title" content={`${data.main.gqlDictionariesAcf.title} | POST-FAKE`} />
        <meta name="twitter:description" content="" />
      </Helmet>
      <div className="dictionary-detail__layout">
        <StickyBottom target={'.util__page-mover'}>
          <OnlyPC>
            <p className="dictionary-detail__content-caption ff-univ-bold">
              POST-FAKE
              <br />
              DICTIONARY
            </p>
          </OnlyPC>
          <OnlySP>
            <p className="dictionary-detail__content-caption ff-univ-bold">POST-FAKE DICTIONARY</p>
          </OnlySP>
        </StickyBottom>

        <div className="dictionary-detail__inner">
          {data.main.gqlDictionaryCategories.nodes.length && (
            <p className="dictionary-detail__character ff-univ">{`${data.main.gqlDictionaryCategories.nodes[0].name.toUpperCase()}${data.main.gqlDictionaryCategories.nodes[0].name.toLowerCase()}`}</p>
          )}
          <div className="dictionary-detail__row util__page">
            <div className="dictionary-detail__column dictionary-detail__column--left">
              {data.main.gqlDictionariesAcf.images &&
                data.main.gqlDictionariesAcf.images[0] &&
                data.main.gqlDictionariesAcf.images[0].image && (
                  <figure className="dictionary-detail__figure">
                    <LazyImage data={data.main.gqlDictionariesAcf.images[0].image} />
                  </figure>
                )}

              <OnlyPC classStr="dictionary-detail__figure-wrap">
                {data.main.gqlDictionariesAcf.images &&
                  data.main.gqlDictionariesAcf.images.map((elem: any, i: number) => {
                    if (i > 0) {
                      return (
                        <figure className="dictionary-detail__figure" key={`dictionary-detail__figure-${i}`}>
                          {elem.image && <LazyImage data={elem.image} />}
                        </figure>
                      );
                    }
                  })}
              </OnlyPC>
            </div>
            <div className="dictionary-detail__column dictionary-detail__column--right">
              <p className="dictionary-detail__sub-caption ff-univ-bold">
                {data.main.gqlDictionariesAcf.title && <strong>{data.main.gqlDictionariesAcf.title.substring(0, 1)}</strong>}
                {data.main.gqlDictionariesAcf.title && (
                  <span>{data.main.gqlDictionariesAcf.title.substring(1, data.main.gqlDictionariesAcf.title.length)}</span>
                )}
                <span className="dictionary-detail__ja-caption">{data.main.gqlDictionariesAcf.subtitle}</span>
              </p>

              <div>
                {data.main.gqlDictionariesAcf.contents &&
                  data.main.gqlDictionariesAcf.contents.map((elem: any, i: number) => {
                    return (
                      <React.Fragment key={`dictionary-elem-${i}`}>
                        {((): any => {
                          if (elem.fieldGroupName === 'GqlDictionaryItem_Gqldictionariesacf_Contents_BlockHeading3') {
                            return (
                              <h1 key={`dictionary-detail-content-${i}`} className="dictionary-detail__caption">
                                {elem.text}
                              </h1>
                            );
                          }
                        })()}

                        {((): any => {
                          if (i === 0) {
                            return (
                              <OnlySP>
                                {data.main.gqlDictionariesAcf.images &&
                                  data.main.gqlDictionariesAcf.images.map((elem: any, k: number) => {
                                    if (k > 0) {
                                      return (
                                        <figure className="dictionary-detail__figure" key={`dictionary-detail__figure-${k}`}>
                                          <LazyImage data={elem.image} />
                                        </figure>
                                      );
                                    }
                                  })}
                              </OnlySP>
                            );
                          }
                        })()}

                        {((): any => {
                          if (elem.fieldGroupName === 'GqlDictionaryItem_Gqldictionariesacf_Contents_BlockTextColumn') {
                            return (
                              <div
                                key={`dictionary-detail-content-${i}`}
                                className="dictionary-detail__content"
                                dangerouslySetInnerHTML={{ __html: String(elem.text) || '' }}></div>
                            );
                          }
                        })()}
                      </React.Fragment>
                    );
                  })}
              </div>

              <p className="dictionary-detail__author ff-mono">{data.main.gqlDictionariesAcf.credit}</p>
            </div>
          </div>
        </div>
        <Sns />
        <BreadCrumb />
      </div>
      <PageMover navigation={navigation} mode={PAGE_MOVER_MODE.DICTIONARY} />
      <OnlyPC>
        <p className="dictionary-detail__back ff-univ-bold">
          <Link to={`${localePath}${PATH.DICTIONARY}`} className="link__alpha">
            A-Z
            <br />
            INDEX
          </Link>
        </p>
      </OnlyPC>
    </article>
  );
};
export default DictionaryDetail;

export const query = graphql`
  query dictionaryDetailQuery($databaseId: Int!, $locale: String!) {
    main: wpGqlDictionaryItem(databaseId: { eq: $databaseId }) {
      databaseId
      translated {
        databaseId
      }
      gqlDictionaryCategories {
        nodes {
          name
        }
      }
      gqlDictionariesAcf {
        title
        subtitle
        contents {
          ... on WpGqlDictionaryItem_Gqldictionariesacf_Contents_BlockHeading3 {
            fieldGroupName
            text
          }
          ... on WpGqlDictionaryItem_Gqldictionariesacf_Contents_BlockTextColumn {
            fieldGroupName
            text
          }
        }
        credit
        images {
          image {
            mediaItemUrl
            mediaDetails {
              height
              width
            }
          }
        }
      }
    }
    navigation: allWpGqlDictionaryItem(filter: { locale: { locale: { eq: $locale } } }) {
      edges {
        node {
          databaseId
          gqlDictionariesAcf {
            title
          }
        }
      }
    }
  }
`;

import React, { useEffect, useState } from 'react';

const StickyBottom: React.FunctionComponent<any> = (props) => {
  const [isFix, setFix] = useState<boolean>(false);

  const onIntersect = (changes: IntersectionObserverEntry[]): void => {
    if (changes[0].isIntersecting) {
      setFix(true);
    } else {
      setFix(false);
    }
  };
  let io: IntersectionObserver | null;

  useEffect(() => {
    io = new IntersectionObserver(onIntersect, { rootMargin: `0px 0px 30px 0px` });
    io.observe(document.querySelector(props.target));

    return () => {
      if (io) {
        io.disconnect();
      }
      io = null;
    };
  }, []);

  return <div className={`util__sticky-bottom ${isFix ? 'fix' : ''}`}>{props.children}</div>;
};
export default StickyBottom;
